import { lazy } from 'react';
import ComponentLoader from 'src/components/ComponentLoader';
import Module from 'src/config/models/Module';

export const DeviceActivationViewModule = new Module({
  id: 'device-activation-view',
  name: 'Device Activation',
  path: 'device-activation/view',
  Component: ComponentLoader(
    lazy(() => import('src/content/deviceActivation/View'))
  ),
  actions: {
    changeStatus: {
      id: 'change-status-device-activation-result-table',
      name: 'change-status-device-activation-result',
      label: 'Change Status'
    }
  }
});

const DeviceActivationModules = [DeviceActivationViewModule];

export default DeviceActivationModules;
