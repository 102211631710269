import { PERMISSIONS_URL } from 'src/config/api';
import ApiService from './ApiService';

class PermissionsService extends ApiService {
  constructor() {
    super(PERMISSIONS_URL);
  }
}

export default new PermissionsService();
