import { lazy } from 'react';
import ComponentLoader from 'src/components/ComponentLoader';
import Module from 'src/config/models/Module';

export const OrganizationViewModule = new Module({
  id: 'organization-view',
  name: 'Organizations',
  path: 'organizations/view',
  Component: ComponentLoader(
    lazy(() => import('src/content/organization/View'))
  )
});

export const OrganizationAddModule = new Module({
  id: 'organization-add',
  name: 'OrganizationAdd',
  path: 'organizations/add',
  Component: ComponentLoader(
    lazy(() => import('src/content/organization/Form'))
  )
});

// export const OrganizationEditModule = new Module({
//   id: 'organization-edit',
//   name: 'OrganizationEdit',
//   path: 'organizations/edit',
//   pathDef: 'organizations/edit/:id',
//   Component: ComponentLoader(
//     lazy(() => import('src/content/organization/Form'))
//   )
// });

const OrganizationModules = [
  OrganizationViewModule,
  OrganizationAddModule
  // OrganizationEditModule
];

export default OrganizationModules;
