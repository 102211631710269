import { lazy } from 'react';
import ComponentLoader from 'src/components/ComponentLoader';
import Module from 'src/config/models/Module';

export const WalletViewModule = new Module({
  id: 'wallet-view',
  name: 'Wallet',
  label: 'Wallet',
  path: 'wallet/view',
  Component: ComponentLoader(lazy(() => import('src/content/wallet/View')))
  //   actions: {
  //     edit: {
  //       id: 'edit-wallet-result-table',
  //       name: 'edit',
  //       label: 'Edit'
  //     },
  //     delete: {
  //       id: 'delete-wallet-result-table',
  //       name: 'delete',
  //       label: 'Delete'
  //     }
  //   }
});

const WalletModules = [WalletViewModule];

export default WalletModules;
