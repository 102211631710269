import { mock } from 'src/utils/axios';
import { v4 as uuid } from 'uuid';

const vehicleOperation = [
  {
    id: uuid(),
    date: "8th May' 22",
    vehicleRegNo: 'C8/W51-A/RJ01GB7858',
    ward: 'Ward 026',
    circle: 'Circle 01',
    driverName: 'Jagat',
    driverContact: '9937789586',
    operationStatus: 'IN OPERATION'
  },
  {
    id: uuid(),
    date: "10th May' 22",
    vehicleRegNo: 'C9/W72-B/RJ01GB7902',
    ward: 'Ward 020',
    circle: 'Circle 03',
    driverName: 'Ramanujan',
    driverContact: '7657789516',
    operationStatus: 'NOT IN OPERATION'
  },
  {
    id: uuid(),
    date: "11th May' 22",
    vehicleRegNo: 'C4/W22-A/RJ01GB7909',
    ward: 'Ward 025',
    circle: 'Circle 013',
    driverName: 'Harendra',
    driverContact: '6347959535',
    operationStatus: 'IN OPERATION'
  },
  {
    id: uuid(),
    date: "15th May' 22",
    vehicleRegNo: 'C8/W55-B/RJ01GB7883',
    ward: 'Ward 046',
    circle: 'Circle 011',
    driverName: 'Ganga Ram',
    driverContact: '7237616573',
    operationStatus: 'IN OPERATION'
  },
  {
    id: uuid(),
    date: "20th May' 22",
    vehicleRegNo: 'C10/W74-A/RJ01GB7870',
    ward: 'Ward 030',
    circle: 'Circle 012',
    driverName: 'Gurupreet',
    driverContact: '7826542559',
    operationStatus: 'NOT IN OPERATION'
  },
  {
    id: uuid(),
    date: "21th May' 22",
    vehicleRegNo: 'C8/W54-A/RJ01GB7894',
    ward: 'Ward 035',
    circle: 'Circle 013',
    driverName: 'Devilal',
    driverContact: '7264651607',
    operationStatus: 'IN OPERATION'
  },
  {
    id: uuid(),
    date: "22th May' 22",
    vehicleRegNo: 'C1/W2-A/RJ01GB7881',
    ward: 'Ward 050',
    circle: 'Circle 014',
    driverName: 'Damodhara',
    driverContact: '9816750609',
    operationStatus: 'IN OPERATION'
  },
  {
    id: uuid(),
    date: "25th May' 22",
    vehicleRegNo: 'C10/W77-B/RJ01GB7867',
    ward: 'Ward 02',
    circle: 'Circle 05',
    driverName: 'Arjun',
    driverContact: '8831649443',
    operationStatus: 'IN OPERATION'
  },
  {
    id: uuid(),
    date: "26th May' 22",
    vehicleRegNo: 'C5/W32-B/RJ01GB7891',
    ward: 'Ward 015',
    circle: 'Circle 01',
    driverName: 'Bharat',
    driverContact: '7067178616',
    operationStatus: 'IN OPERATION'
  },
  {
    id: uuid(),
    date: "27th May' 22",
    vehicleRegNo: 'C8/W60-B/RJ01GB6878',
    ward: 'Ward 042',
    circle: 'Circle 010',
    driverName: 'Amrinder',
    driverContact: '7837659586',
    operationStatus: 'NOT IN OPERATION'
  }
];

mock.onGet('/api/operation-status').reply(() => {
  return [200, { vehicleOperation }];
});
