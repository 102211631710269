import { lazy } from 'react';
import ComponentLoader from 'src/components/ComponentLoader';
import Module from 'src/config/models/Module';

export const UserPermissionsEditModule = new Module({
  id: 'user-permissions-edit',
  name: 'User Permissions',
  path: 'user-permissions/edit',
  Component: ComponentLoader(lazy(() => import('src/content/permissions/Form')))
});

const UserPermissionsModules = [UserPermissionsEditModule];

export default UserPermissionsModules;
