import { lazy } from 'react';
import ComponentLoader from 'src/components/ComponentLoader';
import Module from 'src/config/models/Module';

export const PlanViewModule = new Module({
  id: 'plans-view',
  name: 'Plans',
  label: 'Plans',
  path: 'plans/view',
  Component: ComponentLoader(lazy(() => import('src/content/plans/View'))),
  actions: {
    edit: {
      id: 'edit-plans-result-table',
      name: 'edit',
      label: 'Edit'
    },
    delete: {
      id: 'delete-plans-result-table',
      name: 'delete',
      label: 'Delete'
    }
  }
});

export const PlanAddModule = new Module({
  id: 'plans-add',
  name: 'plansAdd',
  path: 'plans/add',
  pathDef: 'plans/add',
  Component: ComponentLoader(lazy(() => import('src/content/plans/Form')))
});

export const PlanEditModule = new Module({
  id: 'plans-edit',
  name: 'plansEdit',
  path: 'plans/edit',
  pathDef: 'plans/edit/:id',
  Component: ComponentLoader(lazy(() => import('src/content/plans/Form')))
});

const PlanModules = [PlanViewModule, PlanAddModule, PlanEditModule];

export default PlanModules;
