import { lazy } from 'react';
import ComponentLoader from 'src/components/ComponentLoader';
import Module from 'src/config/models/Module';

export const SaleViewModule = new Module({
  id: 'sales-view',
  name: 'Sales Orders',
  path: 'sales/view',
  Component: ComponentLoader(lazy(() => import('src/content/sales/View'))),
  actions: {
    showItems: {
      id: 'show-items-sales-order-result-table',
      name: 'showItems',
      label: 'Show Items'
    },
    showTransactions: {
      id: 'view-transactions',
      name: 'view-transactions',
      label: 'View Transactions'
    },
    salesWidget: {
      id: 'sales-widget',
      name: 'sales-widget'
    }
  }
});

export const PurchaseNonAdminViewModule = new Module({
  id: 'purchase-non-admin-view',
  name: 'Dealer Puchase Orders',
  path: 'sales/nAdmin',
  Component: ComponentLoader(lazy(() => import('src/content/sales/View'))),
  actions: {
    showItems: {
      id: 'show-items-sales-order-result-table',
      name: 'showItems',
      label: 'Show Items'
    },
    showTransactions: {
      id: 'view-transactions',
      name: 'view-transactions',
      label: 'View Transactions'
    },
    salesWidget: {
      id: 'sales-widget',
      name: 'sales-widget'
    }
  }
});

export const SaleAddModule = new Module({
  id: 'sales-add',
  name: 'SalesEdit',
  path: 'sales/add',
  Component: ComponentLoader(lazy(() => import('src/content/sales/Form')))
});

export const SaleEditModule = new Module({
  id: 'sales-edit',
  name: 'SalesEdit',
  path: 'sales/edit',
  pathDef: 'sales/edit/:id',
  Component: ComponentLoader(lazy(() => import('src/content/sales/Form'))),
  actions: {
    edit: {
      id: 'edit-sales-result-table',
      name: 'edit-sales-order',
      label: 'Edit'
    },
    delete: {
      id: 'delete-sales-result-table',
      name: 'delete-sales-order',
      label: 'Delete'
    },
    changeStatus: {
      id: 'change-sales-order-status',
      name: 'change-sales-order-status',
      label: 'Change Status'
    }
  }
});

const SaleModules = [
  SaleViewModule,
  SaleAddModule,
  SaleEditModule,
  PurchaseNonAdminViewModule
];

export default SaleModules;
