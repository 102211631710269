import { Box } from '@mui/material';
import HeaderNotifications from './Notifications';
// import LanguageSwitcher from './LanguageSwitcher';
import FullscreenSwitcher from './FullScreenSwitcher';

function HeaderButtons() {
  return (
    <Box display="flex" justifyContent="space-evenly" alignItems="flex-end">
      <HeaderNotifications />
      <FullscreenSwitcher />
      {/* <LanguageSwitcher /> */}
    </Box>
  );
}

export default HeaderButtons;
