import { mock } from 'src/utils/axios';
import { v4 as uuid } from 'uuid';

let alerts = [
  {
    id: uuid(),
    time: "7th May' 22, 07:14:50 AM",
    deviceName: 'C5/W31-A/RJ01GB7884',
    type: 'Geofence Exit Alarm WARD 8 TIPPER ROUTE',
    geofence: 'WARD 8 TIPPER ROUTE'
  },
  {
    id: uuid(),
    time: "7th May' 22, 09:14:50 AM",
    deviceName: 'C8/W54-A/RJ01GB7894',
    type: 'Geofence Exit Alarm WARD 19 TIPPER ROUTE',
    geofence: 'WARD 19 TIPPER ROUTE'
  },
  {
    id: uuid(),
    time: "7th May' 22, 06:14:50 AM",
    deviceName: 'C8/W54-A/RJ01GB7894',
    type: 'Geofence Exit Alarm WARD 21 TIPPER ROUTE',
    geofence: 'WARD 21 TIPPER ROUTE'
  },
  {
    id: uuid(),
    time: "7th May' 22, 08:14:50 AM",
    deviceName: 'C5/W31-A/RJ01GB7884',
    type: 'Geofence Exit Alarm WARD 21 TIPPER ROUTE',
    geofence: 'WARD 21 TIPPER ROUTE'
  },
  {
    id: uuid(),
    time: "6th May' 22, 07:14:50 AM",
    deviceName: 'C5/W31-A/RJ01GB7884',
    type: 'Geofence Exit Alarm WARD 21 TIPPER ROUTE',
    geofence: 'WARD 21 TIPPER ROUTE'
  },
  {
    id: uuid(),
    time: "6th May' 22, 07:11:43 AM",
    deviceName: 'C2/W69/RJ01GB7862',
    type: 'Geofence Enter Alarm WARD 64 TIPPER ROUTE',
    geofence: 'WARD 64 TIPPER ROUTE'
  },
  {
    id: uuid(),
    time: "6th May' 22, 07:11:50 AM",
    deviceName: 'C2/W69/RJ01GB7862',
    type: 'Geofence Exit Alarm WARD 11 TIPPER ROUTE',
    geofence: 'WARD 11 TIPPER ROUTE'
  },
  {
    id: uuid(),
    time: "6th May' 22, 07:11:50 AM",
    deviceName: 'C5/W31-A/RJ01GB7884',
    type: 'Geofence Exit Alarm WARD 30 TIPPER ROUTE',
    geofence: 'WARD 30 TIPPER ROUTE'
  },
  {
    id: uuid(),
    time: "6th May' 22, 07:14:50 AM",
    deviceName: 'C5/W31-A/RJ01GB7884',
    type: 'Geofence Exit Alarm WARD 21 TIPPER ROUTE',
    geofence: 'WARD 21 TIPPER ROUTE'
  },
  {
    id: uuid(),
    time: "6th May' 22, 07:14:50 AM",
    deviceName: 'C5/W31-A/RJ01GB7884',
    type: 'Geofence Exit Alarm WARD 21 TIPPER ROUTE',
    geofence: 'WARD 21 TIPPER ROUTE'
  }
];

mock.onGet('/api/alerts').reply(() => {
  return [200, { alerts }];
});

mock.onGet('/api/alert').reply((config) => {
  const { productId } = config.params;
  const alert = alerts.find((_product) => _product.id === productId);

  return [200, { alert }];
});
