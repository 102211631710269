import { createSlice } from '@reduxjs/toolkit';
import OrganizationService from 'src/services/OrganizationService';

const initialState = null;

const slice = createSlice({
  name: 'organization',
  initialState,
  reducers: {
    getOrganization(state, action) {
      if (state) return state;
      state = action.payload;
      return state;
    }
  }
});

export const reducer = slice.reducer;

export const getOrganization = () => async (dispatch) => {
  const response = await OrganizationService.get({
    _id: '63c91e397e9bc20fd580087a'
  });

  dispatch(slice.actions.getOrganization(response.data));
};

export default slice;
