import { lazy } from 'react';
import ComponentLoader from 'src/components/ComponentLoader';
import Module from 'src/config/models/Module';

export const ReturnOrderViewModule = new Module({
  id: 'return-orders-view',
  name: 'Return Order',
  path: 'return-orders/view',
  Component: ComponentLoader(
    lazy(() => import('src/content/returnOrder/View'))
  ),
  actions: {
    showItems: {
      id: 'show-items-sales-order-result-table',
      name: 'showItems',
      label: 'Show Items'
    },
    showTransactions: {
      id: 'view-transactions-return-order-result-table',
      name: 'view-transactions-return-order-result',
      label: 'View Transactions'
    }
  }
});

export const ReturnOrderAddModule = new Module({
  id: 'return-orders-add',
  name: 'ReturnOrderAdd',
  path: 'return-orders/add',
  pathDef: 'return-orders/add',
  Component: ComponentLoader(
    lazy(() => import('src/content/returnOrder/Form'))
  ),
  actions: {
    add: {
      id: 'add-return-order',
      name: 'addReturnOrder',
      label: 'Add'
    }
  }
});

export const ReturnOrderEditModule = new Module({
  id: 'return-orders-edit',
  name: 'ReturnOrderEdit',
  path: 'return-orders/edit',
  pathDef: 'return-orders/edit/:id',
  Component: ComponentLoader(
    lazy(() => import('src/content/returnOrder/Form'))
  ),
  actions: {
    edit: {
      id: 'edit-return-order-result-table',
      name: 'edit-return-order-result',
      label: 'Edit'
    },
    delete: {
      id: 'delete-return-order-result-table',
      name: 'delete-return-order-result',
      label: 'Delete'
    },
    changeStatus: {
      id: 'change-status-return-order-result-table',
      name: 'change-status-return-order-result',
      label: 'Change Status'
    }
  }
});

const ReturnOrderModules = [
  ReturnOrderViewModule,
  ReturnOrderAddModule,
  ReturnOrderEditModule
];

export default ReturnOrderModules;
