import joinUrlPath from 'src/utils/joinUrlPath';

import {
  doPost,
  doGet,
  doGetById,
  doPut,
  doPatch,
  doDelete
} from 'src/utils/apiCallers';

const getDefaultParams = () => {
  return {};
};

// const getToken = () =>
//   localStorage.getItem(
//     'CognitoIdentityServiceProvider.5ibpo3jkmboi3hn51qt8usrdi1.c124b7e6-ba29-48a3-86e4-d26f951ca915.idToken'
//   );

class ApiService {
  _url = '';

  constructor(url) {
    this._url = url;
    this._headers = {};

    this.cancelToken = '';

    this.doPost = doPost;

    this.doGet = doGet;
    this.doGetById = doGetById;

    this.doPut = doPut;
    this.doPatch = doPatch;

    this.doDelete = doDelete;
  }

  post(payload, params, headers) {
    return doPost(this._url, payload, params, { ...this._headers, ...headers });
  }

  get(params, headers, noOrgId) {
    const _params = { ...params, ...getDefaultParams() };

    if (noOrgId) delete _params.organizationId;

    return this.doGet(
      this._url,
      _params,
      { ...this._headers, ...headers },
      this.cancelToken
    );
  }

  getById(id, params, headers) {
    const _params = { ...params, _id: id, ...getDefaultParams() };
    return this.doGetById(this._url, _params, { ...this._headers, ...headers });
  }

  put(id, payload, params, headers) {
    return this.doPut(joinUrlPath(this._url, id), payload, params, {
      ...this._headers,
      ...headers
    });
  }

  delete(id, params, headers) {
    return this.doDelete(joinUrlPath(this._url, id), params, {
      ...this._headers,
      ...headers
    });
  }

  patch(id, payload, params, headers) {
    return this.doPatch(joinUrlPath(this._url, id), payload, params, {
      ...this._headers,
      ...headers
    });
  }

  cancelReq() {
    this.cancelToken?.cancel();
  }
}

export default ApiService;
