import { lazy } from 'react';
import ComponentLoader from 'src/components/ComponentLoader';
import Module from 'src/config/models/Module';

export const PurchaseOrderViewModule = new Module({
  id: 'purchase-order-view',
  name: 'Purchase Orders',
  path: 'purchase-orders/view',
  Component: ComponentLoader(
    lazy(() => import('src/content/purchaseOrder/View'))
  ),
  actions: {
    viewTransactions: {
      id: 'view-transactions-purchase-order-result-table',
      name: 'viewTransactions',
      label: 'View Transactions'
    }
  }
});

export const PurchaseOrderAddModule = new Module({
  id: 'purchase-order-add',
  name: 'PurchaseOrdersEdit',
  path: 'purchase-orders/add',
  Component: ComponentLoader(
    lazy(() => import('src/content/purchaseOrder/Form'))
  )
});

export const PurchaseOrderEditModule = new Module({
  id: 'purchase-order-edit',
  name: 'PurchaseOrdersEdit',
  path: 'purchase-orders/edit',
  pathDef: 'purchase-orders/edit/:id',
  Component: ComponentLoader(
    lazy(() => import('src/content/purchaseOrder/Form'))
  ),
  actions: {
    edit: {
      id: 'edit-purchase-order-result-table',
      name: 'edit',
      label: 'Edit'
    },
    delete: {
      id: 'delete-purchase-order-result-table',
      name: 'delete',
      label: 'Delete'
    },
    changeStatus: {
      id: 'change-status-purchase-order-result-table',
      name: 'changeStatus',
      label: 'Change Status'
    }
  }
});

const PurchaseOrderModules = [
  PurchaseOrderViewModule,
  PurchaseOrderAddModule,
  PurchaseOrderEditModule
];

export default PurchaseOrderModules;
