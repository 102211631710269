import { lazy } from 'react';
import ComponentLoader from 'src/components/ComponentLoader';
import Module from 'src/config/models/Module';

export const WarehouseViewModule = new Module({
  id: 'warehouse-view',
  name: 'Warehouses',
  path: 'warehouses/view',
  Component: ComponentLoader(lazy(() => import('src/content/warehouse/View')))
});

export const WarehouseAddModule = new Module({
  id: 'warehouse-add',
  name: 'WarehouseAdd',
  path: 'warehouses/add',
  Component: ComponentLoader(lazy(() => import('src/content/warehouse/Form')))
});

export const WarehouseEditModule = new Module({
  id: 'warehouse-edit',
  name: 'WarehouseEdit',
  path: 'warehouses/edit',
  pathDef: 'warehouses/edit/:id',
  Component: ComponentLoader(lazy(() => import('src/content/warehouse/Form'))),
  actions: {
    edit: {
      id: 'edit-warehouse-result-table',
      name: 'edit-warehouse-result-table',
      label: 'Edit'
    },
    delete: {
      id: 'delete-warehouse-result-table',
      name: 'delete-warehouse-result-table',
      label: 'Delete'
    }
  }
});

const WarehouseModules = [
  WarehouseViewModule,
  WarehouseAddModule,
  WarehouseEditModule
];

export default WarehouseModules;
