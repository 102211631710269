import isProd from 'src/isProd';

export const STAGE_URL = 'https://inventory-dev.parchai.io/v1';
export const PROD_URL = 'https://inventory.parchai.io/v1';

export const LOCAL_BASE_URL = 'http://localhost:9090/dev';

export const BASE_URL = isProd ? PROD_URL : STAGE_URL;
// export const BASE_URL = LOCAL_BASE_URL;

export const RTO_URL = `${BASE_URL}/config/RTOCodes`;
export const SKU_URL = `${BASE_URL}/sku`;
export const BULK_ITEM_URL = `${BASE_URL}/item/bulk`;
export const BULK_DEVICE_ACTIVATION = `${BASE_URL}/device/activation`;
export const ITEM_URL = `${BASE_URL}/item`;
export const TRANSACTION_URL = `${BASE_URL}/transaction`;
export const USER_URL = `${BASE_URL}/user`;
export const VENDOR_URL = `${BASE_URL}/vendor`;
export const CUSTOMER_URL = `${BASE_URL}/customer`;
export const USER_CUSTOMER_URL = `${BASE_URL}/user/from-customer`;
export const INVENTORY_URL = `${BASE_URL}/inventory`;
export const SALES_ORDER_URL = `${BASE_URL}/salesorder`;
export const PURCHASE_ORDER_URL = `${BASE_URL}/purchaseorder`;
export const ACTIVATION_URL = `${BASE_URL}/activation`;
export const ACTIVATION_SUMMARY_URL = `${BASE_URL}/report/activation/summary`;
export const WAREHOUSE_URL = `${BASE_URL}/warehouse`;
export const ORGANIZATION_URL = `${BASE_URL}/organization`;
export const REPORT_URL = `${BASE_URL}/report`;
export const RETURN_ORDER_URL = `${BASE_URL}/returnorder`;
export const PERMISSIONS_URL = `${BASE_URL}/permissions`;
export const TAGS_URL = `${BASE_URL}/tags`;
export const PLAN_URL = `${BASE_URL}/plan`;
export const SIM_ACTIVATION_URL = `${BASE_URL}/simactivationorders`;
export const WALLET_URL = `${BASE_URL}/wallet`;
