import { mock } from 'src/utils/axios';
import { v4 as uuid } from 'uuid';

const d2dwasteCollected = [
  {
    id: uuid(),
    date: "8th May' 22",
    ward: 'Ward 021',
    circle: 'Circle 08',
    totalHouseholds: 320,
    wasteCollected: 100,
    completion: 31.25
  },
  {
    id: uuid(),
    date: "10th May' 22",
    ward: 'Ward 025',
    circle: 'Circle 06',
    totalHouseholds: 320,
    wasteCollected: 110,
    completion: 34.375
  },
  {
    id: uuid(),
    date: "9th May' 22",
    ward: 'Ward 020',
    circle: 'Circle 18',
    totalHouseholds: 320,
    wasteCollected: 200,
    completion: 62.5
  },
  {
    id: uuid(),
    date: "15th May' 22",
    ward: 'Ward 040',
    circle: 'Circle 010',
    totalHouseholds: 320,
    wasteCollected: 235,
    completion: 73.437
  },
  {
    id: uuid(),
    date: "18th May' 22",
    ward: 'Ward 044',
    circle: 'Circle 015',
    totalHouseholds: 320,
    wasteCollected: 250,
    completion: 78.125
  },
  {
    id: uuid(),
    date: "19th May' 22",
    ward: 'Ward 012',
    circle: 'Circle 02',
    totalHouseholds: 320,
    wasteCollected: 310,
    completion: 96.875
  },
  {
    id: uuid(),
    date: "21th May' 22",
    ward: 'Ward 030',
    circle: 'Circle 017',
    totalHouseholds: 320,
    wasteCollected: 315,
    completion: 98.4375
  },
  {
    id: uuid(),
    date: "23th May' 22",
    ward: 'Ward 042',
    circle: 'Circle 02',
    totalHouseholds: 320,
    wasteCollected: 287,
    completion: 89.687
  },
  {
    id: uuid(),
    date: "25th May' 22",
    ward: 'Ward 02',
    circle: 'Circle 03',
    totalHouseholds: 320,
    wasteCollected: 190,
    completion: 59.375
  },
  {
    id: uuid(),
    date: "26th May' 22",
    ward: 'Ward 01',
    circle: 'Circle 016',
    totalHouseholds: 320,
    wasteCollected: 264,
    completion: 82.5
  }
];

mock.onGet('/api/doortodoor-collected').reply(() => {
  return [200, { d2dwasteCollected }];
});
