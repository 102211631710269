import { lazy } from 'react';
import ComponentLoader from 'src/components/ComponentLoader';
import Module from 'src/config/models/Module';

export const SKUViewModule = new Module({
  id: 'sku-view',
  name: 'SKU',
  label: 'SKU',
  path: 'sku/view',
  Component: ComponentLoader(lazy(() => import('src/content/sku/View'))),
  actions: {
    edit: {
      id: 'edit-sku-result-table',
      name: 'edit',
      label: 'Edit'
    },
    delete: {
      id: 'delete-sku-result-table',
      name: 'delete',
      label: 'Delete'
    }
  }
});

export const SKUAddModule = new Module({
  id: 'sku-add',
  name: 'SKUEdit',
  path: 'sku/add',
  pathDef: 'sku/add',
  Component: ComponentLoader(lazy(() => import('src/content/sku/Form')))
});

export const SKUEditModule = new Module({
  id: 'sku-edit',
  name: 'SKUEdit',
  path: 'sku/edit',
  pathDef: 'sku/edit/:id',
  Component: ComponentLoader(
    lazy(() => import('src/content/sku/Form/index_prev'))
  )
});

const SKUModules = [SKUViewModule, SKUAddModule, SKUEditModule];

export default SKUModules;
