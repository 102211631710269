import { lazy } from 'react';
import ComponentLoader from 'src/components/ComponentLoader';
import Module from 'src/config/models/Module';

export const UserViewModule = new Module({
  id: 'user-view',
  name: 'Users',
  label: 'User',
  path: 'users/view',
  Component: ComponentLoader(lazy(() => import('src/content/users/View'))),
  actions: {
    createWallet: {
      id: 'create:wallet',
      name: 'create:wallet',
      label: 'Create Wallet'
    },
    viewWallet: {
      id: 'view:wallet',
      name: 'view:wallet',
      label: 'View Wallet'
    }
  }
});

export const UserAddModule = new Module({
  id: 'user-add',
  name: 'UserAdd',
  path: 'users/add',
  Component: ComponentLoader(lazy(() => import('src/content/users/Form'))),
  actions: {
    add: {
      id: 'add:user',
      name: 'add:user',
      label: 'Create User'
    },
    createVLTDUser: {
      id: 'create:vltduser',
      name: 'create:vltduser',
      label: 'Create VLTD User'
    }
  }
});

export const UserEditModule = new Module({
  id: 'user-edit',
  name: 'UserEdit',
  path: 'users/edit',
  pathDef: 'users/edit/:id',
  Component: ComponentLoader(lazy(() => import('src/content/users/Form'))),
  actions: {
    edit: {
      id: 'edit-users-result-table',
      name: 'edit-users-result-table',
      label: 'Edit'
    },
    delete: {
      id: 'delete-users-result-table',
      name: 'delete-users-result-table',
      label: 'Delete'
    }
  }
});

const UserModules = [UserViewModule, UserAddModule, UserEditModule];

export default UserModules;
