import { ORGANIZATION_URL } from 'src/config/api';
import ApiService from './ApiService';

class OrganizationService extends ApiService {
  constructor() {
    super(ORGANIZATION_URL);
  }
}

export default new OrganizationService();
