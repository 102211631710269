import { lazy } from 'react';
import ComponentLoader from 'src/components/ComponentLoader';
import Module from 'src/config/models/Module';

export const CustomerViewModule = new Module({
  id: 'customer-view',
  name: 'Customers',
  path: 'customers/view',
  Component: ComponentLoader(lazy(() => import('src/content/customers/View')))
});

export const CustomerAddModule = new Module({
  id: 'customer-add',
  name: 'CustomersAdd',
  path: 'customers/add',
  Component: ComponentLoader(lazy(() => import('src/content/customers/Form')))
});

export const CustomerEditModule = new Module({
  id: 'customer-edit',
  name: 'CustomersEdit',
  path: 'customers/edit',
  pathDef: 'customers/edit/:id',
  Component: ComponentLoader(lazy(() => import('src/content/customers/Form'))),
  actions: {
    edit: {
      id: 'edit-customer-result-table',
      name: 'edit-customer-result-table',
      label: 'Edit'
    },
    delete: {
      id: 'delete-customers-result-table',
      name: 'delete-customers-result-table',
      label: 'Delete'
    }
  }
});

const CustomerModules = [
  CustomerViewModule,
  CustomerAddModule,
  CustomerEditModule
];

export default CustomerModules;
