import { createContext, useEffect, useReducer } from 'react';
import PropTypes from 'prop-types';
import UserService from 'src/services/UserService';
import Amplify, { Auth } from 'aws-amplify';
import PermissionsService from 'src/services/PermissionsService';

import { amplifyConfig } from '../config';

Amplify.configure(amplifyConfig);

const initialAuthState = {
  isAuthenticated: false,
  isInitialized: false,
  permissions: [],
  user: null
};

const handlers = {
  INITIALIZE: (state, action) => {
    const { isAuthenticated, user, permissions } = action.payload;

    return {
      ...state,
      isAuthenticated,
      isInitialized: true,
      permissions,
      user
    };
  },
  LOGIN: (state, action) => {
    const { user, permissions } = action.payload;

    return {
      ...state,
      isAuthenticated: true,
      permissions,
      user
    };
  },
  LOGOUT: (state) => ({
    ...state,
    isAuthenticated: false,
    user: null
  }),
  REGISTER: (state) => ({ ...state }),
  VERIFY_CODE: (state) => ({ ...state }),
  RESEND_CODE: (state) => ({ ...state }),
  PASSWORD_RECOVERY: (state) => ({ ...state }),
  PASSWORD_RESET: (state) => ({ ...state })
};

const reducer = (state, action) =>
  handlers[action.type] ? handlers[action.type](state, action) : state;

const AuthContext = createContext({
  ...initialAuthState,
  method: 'Amplify',
  login: () => Promise.resolve(),
  logout: () => Promise.resolve(),
  register: () => Promise.resolve(),
  verifyCode: () => Promise.resolve(),
  resendCode: () => Promise.resolve(),
  passwordRecovery: () => Promise.resolve(),
  passwordReset: () => Promise.resolve()
});

export const AuthProvider = (props) => {
  const { children } = props;
  const [state, dispatch] = useReducer(reducer, initialAuthState);

  useEffect(() => {
    const initialize = async () => {
      try {
        const cognitoUser = await Auth.currentAuthenticatedUser();

        const { data } = await UserService.get({
          userSub: cognitoUser?.attributes?.sub
        });

        const user = data[0];

        let permissions = [];
        if (user) {
          const { data } = await PermissionsService.get({ role: user.role });

          const permissionObj = data[0]?.permissions ?? {};

          permissions = Object.keys(permissionObj)
            ?.map((key) => {
              const _permis = [];
              if (permissionObj[key]?.allowed) _permis.push(key);
              if (permissionObj[key]?.actions)
                _permis.push(
                  ...Object.keys(permissionObj[key]?.actions).filter(
                    (_key) => !!permissionObj[key]?.actions[_key]
                  )
                );

              return _permis;
            })
            .flat(Infinity)
            .filter(Boolean);
        }

        dispatch({
          type: 'INITIALIZE',
          payload: {
            isAuthenticated: true,
            permissions,
            user: {
              id: user._id,
              avatar: user.avatar,
              email: user.email,
              name: `${user.firstName} ${user.lastName}`,
              lastName: user.lastName,
              role: user.role,
              organization: user.organization,
              warehouse: user.warehouse
            }
          }
        });
      } catch (error) {
        dispatch({
          type: 'INITIALIZE',
          payload: {
            isAuthenticated: false,
            user: null
          }
        });
      }
    };

    initialize();
  }, []);

  const login = async (phoneNumber, password) => {
    const handleCannotLogin = (cognitoUser) => {
      if (cognitoUser?.challengeName === 'NEW_PASSWORD_REQUIRED') {
        Auth.completeNewPassword(cognitoUser, 'password1$');
      }
    };

    try {
      const cognitoUser = await Auth.signIn(`+91${phoneNumber}`, password);

      if (!cognitoUser.signInUserSession) {
        handleCannotLogin(cognitoUser);
        return;
      }

      const { data } = await UserService.get({ phoneNumber });

      const user = data[0];

      let permissions = [];
      if (user) {
        const { data } = await PermissionsService.get({ role: user.role });

        const permissionObj = data[0]?.permissions ?? {};

        permissions = Object.keys(permissionObj)
          ?.map((key) => {
            const _permis = [];
            if (permissionObj[key]?.allowed) _permis.push(key);
            if (permissionObj[key]?.actions)
              _permis.push(
                ...Object.keys(permissionObj[key]?.actions).filter(
                  (_key) => !!permissionObj[key]?.actions[_key]
                )
              );

            return _permis;
          })
          .flat(Infinity)
          .filter(Boolean);
      }

      dispatch({
        type: 'LOGIN',
        payload: {
          permissions,
          user: {
            id: user._id,
            avatar: user.avatar,
            email: user.email,
            name: user.firstName,
            lastName: user.lastName,
            role: user.role,
            organization: user.organization,
            warehouse: user.warehouse
          }
        }
      });
      window.location.reload();
    } catch (error) {
      console.log('asdknasijdn', error);
      // eslint-disable-next-line consistent-return
      return error;
    }
  };

  const logout = async () => {
    await Auth.signOut();
    dispatch({
      type: 'LOGOUT'
    });
  };

  const register = async (email, password) => {
    await Auth.signUp({
      username: email,
      password,
      attributes: { email }
    });
    dispatch({
      type: 'REGISTER'
    });
  };

  const verifyCode = async (username, code) => {
    await Auth.confirmSignUp(username, code);
    dispatch({
      type: 'VERIFY_CODE'
    });
  };

  const resendCode = async (username) => {
    await Auth.resendSignUp(username);
    dispatch({
      type: 'RESEND_CODE'
    });
  };

  const passwordRecovery = async (username) => {
    await Auth.forgotPassword(username);
    dispatch({
      type: 'PASSWORD_RECOVERY'
    });
  };

  const passwordReset = async (username, code, newPassword) => {
    await Auth.forgotPasswordSubmit(username, code, newPassword);
    dispatch({
      type: 'PASSWORD_RESET'
    });
  };

  return (
    <AuthContext.Provider
      value={{
        ...state,
        method: 'Amplify',
        login,
        logout,
        register,
        verifyCode,
        resendCode,
        passwordRecovery,
        passwordReset
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

AuthProvider.propTypes = {
  children: PropTypes.node.isRequired
};

export default AuthContext;
