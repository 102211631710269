/* eslint-disable consistent-return */
/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from 'react';
// import { useTranslation } from 'react-i18next';

import { IconButton, styled } from '@mui/material';

import FullscreenRoundedIcon from '@mui/icons-material/FullscreenRounded';
import FullscreenExitRoundedIcon from '@mui/icons-material/FullscreenExitRounded';

// import { FullScreen, useFullScreenHandle } from 'react-full-screen';

const IconButtonWrapper = styled(IconButton)(
  ({ theme }) => `
          width: ${theme.spacing(7)};
          height: ${theme.spacing(7)};
  `
);

function FullscreenSwitcher() {
  //   const { t } = useTranslation();

  const [isFullScreen, setIsFullscreen] = useState(!document.fullscreenEnabled);
  const [init, setInit] = useState(null);

  const handleClick = (e) => {
    setIsFullscreen((prev) => !prev);
  };

  useEffect(() => {
    handleFullScreen(init);
    setInit(true);
  }, [isFullScreen]);

  const handleFullScreen = (e) => {
    if (!e) return;
    const rooElement = document.documentElement;

    if (isFullScreen) return rooElement.requestFullscreen();
    return document.exitFullscreen();
  };

  return (
    <>
      {/* <Tooltip arrow title={t('Notifications')}> */}
      <IconButtonWrapper color="primary" onClick={handleClick}>
        {!isFullScreen ? (
          <FullscreenRoundedIcon />
        ) : (
          <FullscreenExitRoundedIcon />
        )}
      </IconButtonWrapper>
      {/* </Tooltip> */}
    </>
  );
}

export default FullscreenSwitcher;
