const Role = {
  ADMIN: 'ADMIN',
  OPERATOR: 'OPERATOR',
  VENDOR: 'VENDOR',
  DEALER: 'DEALER',
  DISTRIBUTOR: 'DISTRIBUTOR',
  ACCOUNTS: 'ACCOUNTS',
  FITTER: 'FITTER',
  USER: 'USER'
};

const roles = Object.values(Role);

export { roles, Role };
