import { lazy } from 'react';
import ComponentLoader from 'src/components/ComponentLoader';
import Module from 'src/config/models/Module';

export const InventoryViewModule = new Module({
  id: 'inventory-view',
  name: 'Inventory',
  path: 'inventory/view',
  Component: ComponentLoader(lazy(() => import('src/content/inventory/View'))),
  actions: {
    inventoryWidget: {
      id: 'inventory-widget',
      name: 'inventory-widget'
    }
  }
});

export const InventoryAddModule = new Module({
  id: 'inventory-add',
  name: 'InventoryAdd',
  path: 'inventory/add',
  Component: ComponentLoader(lazy(() => import('src/content/inventory/Form')))
});

export const InventoryEditModule = new Module({
  id: 'inventory-edit',
  name: 'InventoryEdit',
  path: 'inventory/edit',
  pathDef: 'inventory/edit/:id',
  Component: ComponentLoader(lazy(() => import('src/content/inventory/Form')))
});

const InventoryModules = [
  InventoryViewModule,
  InventoryAddModule,
  InventoryEditModule
];

export default InventoryModules;
