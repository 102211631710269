import { lazy } from 'react';
import ComponentLoader from 'src/components/ComponentLoader';
import Module from 'src/config/models/Module';

export const SimActivationViewModule = new Module({
  id: 'simActivationOrder-view',
  name: 'Sim Activation Order',
  label: 'Sim Activation Order',
  path: 'sim-activation-order/view',
  Component: ComponentLoader(
    lazy(() => import('src/content/simActivationOrder/View'))
  ),
  actions: {
    changeStatus: {
      id: 'change-status-result-table',
      name: 'changeStatus',
      label: 'Change Status'
    },
    giveApproval: {
      id: 'give-approval-result-table',
      name: 'giveApproval',
      label: 'Give Approval'
    },
    edit: {
      id: 'edit-simActivationOrder-result-table',
      name: 'edit',
      label: 'Edit'
    },
    delete: {
      id: 'delete-simActivationOrder-result-table',
      name: 'delete',
      label: 'Delete'
    }
  }
});

const SimActivationOrderModules = [SimActivationViewModule];

export default SimActivationOrderModules;
