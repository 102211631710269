import { mock } from 'src/utils/axios';
import { v4 as uuid } from 'uuid';

let compactorTrips = [
  {
    id: uuid(),
    date: "8th May' 22",
    vehicleRegNo: 'C10/W73-A/RJ01GB7880',
    ward: 'Ward 021',
    circle: 'Circle 08',
    tripToCompactor: 2
  },
  {
    id: uuid(),
    date: "9th May' 22",
    vehicleRegNo: 'C4/W26-A/RJ01GB7871',
    ward: 'Ward 020',
    circle: 'Circle 07',
    tripToCompactor: 1
  },
  {
    id: uuid(),
    date: "15th May' 22",
    vehicleRegNo: 'C5/W34-A/RJ01GB7882',
    ward: 'Ward 026',
    circle: 'Circle 13',
    tripToCompactor: 4
  },
  {
    id: uuid(),
    date: "20th May' 22",
    vehicleRegNo: 'C1/W1/RJ01GB7908',
    ward: 'Ward 01',
    circle: 'Circle 02',
    tripToCompactor: 3
  },
  {
    id: uuid(),
    date: "22th May' 22",
    vehicleRegNo: 'C7/W49-B/RJ01GB6872',
    ward: 'Ward 016',
    circle: 'Circle 09',
    tripToCompactor: 5
  },
  {
    id: uuid(),
    date: "23th May' 22",
    vehicleRegNo: 'C8/W51-A/RJ01GB7858',
    ward: 'Ward 026',
    circle: 'Circle 010',
    tripToCompactor: 0
  },
  {
    id: uuid(),
    date: "25th May' 22",
    vehicleRegNo: 'C5/W29-A/RJ01GB7879',
    ward: 'Ward 045',
    circle: 'Circle 11',
    tripToCompactor: 1
  },
  {
    id: uuid(),
    date: "26th May' 22",
    vehicleRegNo: 'C2/W8/RJ01GB6877',
    ward: 'Ward 056',
    circle: 'Circle 09',
    tripToCompactor: 1
  },
  {
    id: uuid(),
    date: "27th May' 22",
    vehicleRegNo: 'C4/W25-B/RJ01GB7907',
    ward: 'Ward 020',
    circle: 'Circle 20',
    tripToCompactor: 3
  },
  {
    id: uuid(),
    date: "29th May' 22",
    vehicleRegNo: 'C8/W58/RJ01GB7890',
    ward: 'Ward 015',
    circle: 'Circle 15',
    tripToCompactor: 2
  }
];

const disposalTrips = [
  {
    date: "8th May' 22",
    vehicleRegNo: 'C10/W73-A/RJ01GB7880',
    ward: 'Ward 021',
    circle: 'Circle 08',
    tripsToDisposal: 2
  },
  {
    date: "9th May' 22",
    vehicleRegNo: 'C4/W26-A/RJ01GB7871',
    ward: 'Ward 020',
    circle: 'Circle 07',
    tripsToDisposal: 3
  },
  {
    date: "15th May' 22",
    vehicleRegNo: 'C5/W34-A/RJ01GB7882',
    ward: 'Ward 026',
    circle: 'Circle 13',
    tripsToDisposal: 1
  },
  {
    date: "20th May' 22",
    vehicleRegNo: 'C1/W1/RJ01GB7908',
    ward: 'Ward 01',
    circle: 'Circle 02',
    tripsToDisposal: 0
  },
  {
    date: "22th May' 22",
    vehicleRegNo: 'C7/W49-B/RJ01GB6872',
    ward: 'Ward 016',
    circle: 'Circle 09',
    tripsToDisposal: 9
  },
  {
    date: "23th May' 22",
    vehicleRegNo: 'C8/W51-A/RJ01GB7858',
    ward: 'Ward 026',
    circle: 'Circle 010',
    tripsToDisposal: 10
  },
  {
    date: "25th May' 22",
    vehicleRegNo: 'C5/W29-A/RJ01GB7879',
    ward: 'Ward 045',
    circle: 'Circle 11',
    tripsToDisposal: 8
  },
  {
    date: "26th May' 22",
    vehicleRegNo: 'C2/W8/RJ01GB6877',
    ward: 'Ward 056',
    circle: 'Circle 09',
    tripsToDisposal: 6
  },
  {
    date: "27th May' 22",
    vehicleRegNo: 'C4/W25-B/RJ01GB7907',
    ward: 'Ward 020',
    circle: 'Circle 20',
    tripsToDisposal: 7
  },
  {
    date: "29th May' 22",
    vehicleRegNo: 'C8/W58/RJ01GB7890',
    ward: 'Ward 015',
    circle: 'Circle 15',
    tripsToDisposal: 5
  }
];

mock.onGet('/api/trips/compactor').reply(() => {
  return [200, { compactorTrips }];
});

mock.onGet('/api/trips/disposal').reply(() => {
  return [200, { disposalTrips }];
});
