import DashboardRoundedIcon from '@mui/icons-material/DashboardRounded';
import AnalyticsRoundedIcon from '@mui/icons-material/AnalyticsRounded';
import PointOfSaleRoundedIcon from '@mui/icons-material/PointOfSaleRounded';
import Inventory2RoundedIcon from '@mui/icons-material/Inventory2Rounded';
import ShoppingCartIcon from '@mui/icons-material/ShoppingCart';
import ToggleOnRoundedIcon from '@mui/icons-material/ToggleOnRounded';
import SimCardIcon from '@mui/icons-material/SimCard';
// import SummarizeIcon from '@mui/icons-material/Summarize';
import KeyboardReturnRoundedIcon from '@mui/icons-material/KeyboardReturnRounded';
import registerModuleWithMenu from 'src/utils/registerModuleWithMenu';
import { ReturnOrderViewModule } from 'src/content/returnOrder/modules';
import { PurchaseOrderViewModule } from 'src/content/purchaseOrder/modules';
import {
  PurchaseNonAdminViewModule,
  SaleViewModule
} from 'src/content/sales/modules';
import { ActivationViewModule } from 'src/content/activation/modules';
import { InventoryViewModule } from 'src/content/inventory/modules';
import { UserViewModule } from 'src/content/users/modules';
import { SKUViewModule } from 'src/content/sku/modules';
import { ItemViewModule } from 'src/content/items/modules';
import { CustomerViewModule } from 'src/content/customers/modules';
import { OrganizationViewModule } from 'src/content/organization/modules';
import { VendorViewModule } from 'src/content/vendors/modules';
import { WarehouseViewModule } from 'src/content/warehouse/modules';
import { UserPermissionsEditModule } from 'src/content/permissions/modules';

import AdminPanelIcon from '@mui/icons-material/AdminPanelSettings';
import { DashboardViewModule } from 'src/content/dashboards/modules';
import { DeviceActivationViewModule } from 'src/content/deviceActivation/modules';
import { PlanViewModule } from 'src/content/plans/modules';
import { SimActivationViewModule } from 'src/content/simActivationOrder/modules';
// import { WalletViewModule } from 'src/content/wallet/module';
// import { RenewalViewModule } from 'src/content/renewal/modules';

// const sortAlphaAsc = (a, b) => a.name.localeCompare(b.name);

const menuItems = [
  {
    heading: '',
    id: 'root',
    path: '',
    items: [
      registerModuleWithMenu(DashboardViewModule, {
        icon: DashboardRoundedIcon
      }),
      registerModuleWithMenu(InventoryViewModule, {
        icon: Inventory2RoundedIcon
      }),
      registerModuleWithMenu(PurchaseOrderViewModule, {
        icon: ShoppingCartIcon
      }),
      registerModuleWithMenu(SaleViewModule, {
        icon: PointOfSaleRoundedIcon
      }),
      registerModuleWithMenu(PurchaseNonAdminViewModule, {
        icon: PointOfSaleRoundedIcon
      }),
      registerModuleWithMenu(ActivationViewModule, {
        icon: ToggleOnRoundedIcon
      }),
      registerModuleWithMenu(SimActivationViewModule, {
        icon: SimCardIcon
      }),
      // registerModuleWithMenu(RenewalViewModule, {
      //   icon: ToggleOnRoundedIcon
      // }),
      registerModuleWithMenu(ReturnOrderViewModule, {
        icon: KeyboardReturnRoundedIcon
      }),

      // {
      //   name: 'Reports',
      //   icon: SummarizeIcon,
      //   link: '/reports',
      //   id: 'reports',
      //   items: [
      //     {
      //       name: 'Alert',
      //       link: 'reports/alert',
      //       id: 'reports-alert'
      //     }
      //   ]
      // },
      {
        name: 'Management',
        icon: AnalyticsRoundedIcon,
        id: 'management',
        path: '/manage',
        items: [
          registerModuleWithMenu(UserViewModule),
          registerModuleWithMenu(SKUViewModule),
          registerModuleWithMenu(ItemViewModule),
          registerModuleWithMenu(PlanViewModule),
          registerModuleWithMenu(DeviceActivationViewModule),
          registerModuleWithMenu(CustomerViewModule),
          registerModuleWithMenu(OrganizationViewModule),
          registerModuleWithMenu(VendorViewModule),
          registerModuleWithMenu(WarehouseViewModule)
          // registerModuleWithMenu(WalletViewModule)
        ]
      },
      {
        name: 'Admin Controls',
        icon: AdminPanelIcon,
        id: 'admin',
        path: '/admin',
        items: [registerModuleWithMenu(UserPermissionsEditModule)]
      }
    ]
  }
];

const filterSidebar = (items, filter) => {
  return (
    items
      // eslint-disable-next-line array-callback-return, consistent-return
      .map((item) => {
        if (item.items) item.items = filterSidebar(item.items, filter);
        if (filter(item.id)) return item;
      })
      .filter(Boolean)
  );
};

// example filter function = ['reports', 'reports-alert', 'dashboard-1', 'base'].includes(id));

// export default filterSidebar(menuItems, (id) =>
//   ['reports', 'reports-alert', 'dashboard-1', 'base'].includes(id)
// );

export { filterSidebar };
export default menuItems;
