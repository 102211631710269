import { lazy } from 'react';
import ComponentLoader from 'src/components/ComponentLoader';
import Module from 'src/config/models/Module';

export const ItemViewModule = new Module({
  id: 'item-view',
  name: 'Items',
  path: 'items/view',
  Component: ComponentLoader(lazy(() => import('src/content/items/View'))),
  actions: {
    view: {
      id: 'view-item',
      name: 'view-items',
      label: 'View Items'
    }
  }
});

export const ItemAddModule = new Module({
  id: 'item-add',
  name: 'ItemAdd',
  path: 'items/add',
  Component: ComponentLoader(lazy(() => import('src/content/items/Form'))),
  actions: {
    add: {
      id: 'add-items',
      name: 'add-items',
      label: 'Add Items'
    }
  }
});

export const ItemEditModule = new Module({
  id: 'item-edit',
  name: 'ItemEdit',
  path: 'items/edit',
  pathDef: 'items/edit/:id',
  Component: ComponentLoader(lazy(() => import('src/content/items/Form'))),
  actions: {
    edit: {
      id: 'edit-items-result-table',
      name: 'edit-items-result-table',
      label: 'Edit'
    },
    delete: {
      id: 'delete-items-result-table',
      name: 'delete-items-result-table',
      label: 'Delete'
    },
    sendMail: {
      id: 'send-mail-item',
      name: 'send-mail-item',
      label: 'Send Mail'
    }
  }
});

const ItemModules = [ItemViewModule, ItemAddModule, ItemEditModule];

export default ItemModules;
