import Authenticated from 'src/components/Authenticated';

import BaseLayout from 'src/layouts/BaseLayout';

// import TopNavigationLayout from 'src/layouts/TopNavigationLayout';
import SideBarNavigation from 'src/layouts/AccentSidebarLayout';
// import AdminAccess from 'src/components/AdminAccess';

import SaleModules from 'src/content/sales/modules';
import registerModulesWithRouter from 'src/utils/registerModulesWithRouter';
import ReturnOrderModules from 'src/content/returnOrder/modules';
import PurchaseOrderModules from 'src/content/purchaseOrder/modules';
import ActionModules from 'src/content/activation/modules';
import InventoryModules from 'src/content/inventory/modules';
import UserModules from 'src/content/users/modules';
import SKUModules from 'src/content/sku/modules';
import ItemModules from 'src/content/items/modules';
import CustomerModules from 'src/content/customers/modules';
import VendorModules from 'src/content/vendors/modules';
import OrganizationModules from 'src/content/organization/modules';
import WarehouseModules from 'src/content/warehouse/modules';
import UserPermissionsModules from 'src/content/permissions/modules';
import DashboardViewModule from 'src/content/dashboards/modules';
import DeviceActivationModules from 'src/content/deviceActivation/modules';
import PlanModules from 'src/content/plans/modules';
import WalletModules from 'src/content/wallet/module';
import SimActivationOrderModules from 'src/content/simActivationOrder/modules';
// import RenewalModules from 'src/content/renewal/modules';
import { Navigate } from 'react-router';

import statusRoutes from './base';
import authRoutes from './auth';

// import purchaseOrderRoutes from './purchaseOrder';

const router = [
  {
    path: 'auth',
    children: authRoutes
  },
  {
    path: '*',
    element: <BaseLayout />,
    children: statusRoutes
  },
  {
    path: '',
    element: (
      <Authenticated>
        <SideBarNavigation />
      </Authenticated>
    ),
    children: [
      {
        path: '/',
        element: <Navigate to="/dashboard" />
      },
      registerModulesWithRouter(DashboardViewModule)
    ]
  },
  {
    path: '',
    element: (
      <Authenticated>
        <SideBarNavigation />
      </Authenticated>
    ),
    children: [
      registerModulesWithRouter(SaleModules),
      registerModulesWithRouter(ReturnOrderModules),
      registerModulesWithRouter(PurchaseOrderModules),
      registerModulesWithRouter(ActionModules),
      // registerModulesWithRouter(RenewalModules),
      registerModulesWithRouter(InventoryModules),
      registerModulesWithRouter(SimActivationOrderModules)
    ]
  },
  {
    path: 'admin',
    element: (
      <Authenticated>
        <SideBarNavigation />
      </Authenticated>
    ),
    children: [registerModulesWithRouter(UserPermissionsModules)]
  },
  {
    path: 'manage',
    id: 'management',
    element: (
      <Authenticated>
        <SideBarNavigation />
      </Authenticated>
    ),
    children: [
      registerModulesWithRouter(UserModules),
      registerModulesWithRouter(SKUModules),
      registerModulesWithRouter(ItemModules),
      registerModulesWithRouter(DeviceActivationModules),
      registerModulesWithRouter(CustomerModules),
      registerModulesWithRouter(VendorModules),
      registerModulesWithRouter(OrganizationModules),
      registerModulesWithRouter(WarehouseModules),
      registerModulesWithRouter(PlanModules),
      registerModulesWithRouter(WalletModules)
    ]
  }
];

export default router;

export const filterRouter = (children, filter) => {
  return (
    children
      // eslint-disable-next-line array-callback-return, consistent-return
      .map((item) => {
        if (item.children) item.children = filterRouter(item.children, filter);
        if (filter(item.id)) return item;
      })
      .filter(Boolean)
  );
};
