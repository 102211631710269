import { lazy } from 'react';
import ComponentLoader from 'src/components/ComponentLoader';
import Module from 'src/config/models/Module';

export const ActivationViewModule = new Module({
  id: 'activation-view',
  name: 'Fitting',
  path: 'activations/view',
  Component: ComponentLoader(lazy(() => import('src/content/activation/View')))
});

export const ActivationAddModule = new Module({
  id: 'activation-add',
  name: 'FittingAdd',
  path: 'activations/add',
  Component: ComponentLoader(lazy(() => import('src/content/activation/Form'))),
  actions: {
    createVLTDFitmentDetails: {
      id: 'create-VLTD-FitmentDetails',
      name: 'create-VLTD-FitmentDetails',
      label: 'Create VLTD Fitment Details'
    }
  }
});

export const ActivationEditModule = new Module({
  id: 'activation-edit',
  name: 'FittingEdit',
  path: '/activations/edit',
  pathDef: 'activations/edit/:id',
  Component: ComponentLoader(lazy(() => import('src/content/activation/Form'))),
  actions: {
    editCustomerDetails: {
      id: 'edit-customer-details-activation',
      name: 'edit-customer-details',
      label: 'Edit Customer Details'
    },
    delete: {
      id: 'delete-activations-result-table',
      name: 'delete-activations-result',
      label: 'Delete'
    },
    edit: {
      id: 'edit-activations-result-table',
      name: 'edit-activations-result',
      label: 'Edit'
    },
    changeStatus: {
      id: 'change-status-activations-result-table',
      name: 'change-status-activations-result',
      label: 'Change Status'
    }
  }
});

const ActivationModules = [
  ActivationViewModule,
  ActivationAddModule,
  ActivationEditModule
];

export default ActivationModules;
