import { lazy } from 'react';
import ComponentLoader from 'src/components/ComponentLoader';
import Module from 'src/config/models/Module';

export const VendorViewModule = new Module({
  id: 'vendor-view',
  name: 'Vendors',
  path: 'vendors/view',
  Component: ComponentLoader(lazy(() => import('src/content/vendors/View')))
});

export const VendorAddModule = new Module({
  id: 'vendor-add',
  name: 'VendorAdd',
  path: 'vendors/add',
  Component: ComponentLoader(lazy(() => import('src/content/vendors/Form')))
});

export const VendorEditModule = new Module({
  id: 'vendor-edit',
  name: 'VendorEdit',
  path: 'vendors/edit',
  pathDef: 'vendors/edit/:id',
  Component: ComponentLoader(lazy(() => import('src/content/vendors/Form'))),
  actions: {
    edit: {
      id: 'edit-vendors-result-table',
      name: 'edit-vendors-result-table',
      label: 'Edit'
    },
    delete: {
      id: 'delete-vendors-result-table',
      name: 'delete-vendors-result-table',
      label: 'Delete'
    }
  }
});

const VendorModules = [VendorViewModule, VendorAddModule, VendorEditModule];

export default VendorModules;
